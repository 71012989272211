<template>
	<div class="non-member">
		<div class="non-member__container">
			<article>
				<div class="about-header">
					<div  class="about-header__inner">
						<p class="about-header__title">気圧予報で体調管理 頭痛ーる for スゴ得</p>
					</div>
					<div class="about-header__caption">
						<p class="about-header__caption--text">
							その頭痛、もしかして<br>お天気が関係しているのかもしれません
						</p>
					</div>
				</div>

				<section class="sugotoku__enrollment--button">
					<img class="dcm_regist_cpButton" src="@/assets/img/sugotoku_enrollment_btn.gif" alt="初回31日間無料！" >
				</section>

				<section class="about-intro">
					<div class="about-intro__inner">
						<h1 class="about-intro__title">「頭痛ーる」とは?</h1>
						<p class="about-intro__caption">
							『雨の日の朝、きまって調子が悪い』『旅行や大事な会議、なるべく頭痛がしない日を選びたい…』 頭痛ーるは気圧の変化による体調不良が起こりそうな時間帯の確認や、痛み・服薬記録ができる気象予報士が開発した気象病対策アプリです。
						</p>
					</div>
				</section>

				<section class="about-ability">
					<!-- slider -->
					<div class="about-ability__slider">
						<div class="about-ability__slider--container swiper-container" id="js_swiper">
							<ul class="swiper-wrapper">
								<li class="about-ability__slider--item swiper-slide">
									<img src="@/assets/img/about_ability_slider_img02.png" alt="あなたの頭痛を予報！">
								</li>
								<li class="about-ability__slider--item swiper-slide">
									<img src="@/assets/img/about_ability_slider_img01.png" alt="予測する">
								</li>
								<li class="about-ability__slider--item swiper-slide">
									<img src="@/assets/img/about_ability_slider_img04.png" alt="痛み&くすり記録">
								</li>
								<li class="about-ability__slider--item swiper-slide">
									<img src="@/assets/img/about_ability_slider_img05.png" alt="痛みノート">
								</li>
								<li class="about-ability__slider--item swiper-slide">
									<img src="@/assets/img/about_ability_slider_img03.png" alt="全国マップ">
								</li>
							</ul>
						</div>
						<div class="about-ability__slider--nav">
							<div class="swiper-button-prev" id="js_swiper_prev"></div>
							<div class="swiper-button-next" id="js_swiper_next"></div>
						</div>
					</div>
					<!-- /slider -->
					<p class="about-ability__caption">「頭痛ーる」は気圧グラフで頭痛などの気象病を事前に予測。痛みや服薬記録を簡単に記録でき、自身の体調管理に役立ちます。</p>
				</section>

				<section class="sugotoku__enrollment--button">
					<img class="dcm_regist_cpButton" src="@/assets/img/sugotoku_enrollment_btn.gif" alt="初回31日間無料！" >
				</section>

				<section class="about-sick">
					<div class="about-sick__inner">
						<h2 class="about-sick__title">気圧が体調に影響!?</h2>
						<p class="about-sick__caption">
							普段の私たちの生活で自身にかかってくる気圧は約15トン！最近の研究では、気圧の変化が頭痛や肩こり、めまいなど、さまざまな不調を引き起こす原因のひとつとして考えられています。
						</p>
					</div>

					<div class="about-sick__illust">
						<p class="about-sick__illust--text">
							体が気圧に合わせようとする際の<br>
							自律神経のストレス反応により不調に…
						</p>
						<img src="@/assets/img/about_sick_img01.png" alt="頭が痛い" class="about-sick__illust--img">
					</div>

					<div class="about-sick__symptoms">
						<p class="about-sick__symptoms--text">
							気象病の主な症状
						</p>
						<table class="about-sick__symptoms--table">
							<tr><td>頭痛</td><td>腰痛</td><td>ぜんそく</td><td>うつ病</td></tr>
							<tr><td>めまい</td><td>肩こり</td><td>低血圧</td><td>吐き気</td></tr>
							<tr><td>イライラ</td><td>関節痛</td><td>心筋梗塞</td><td>脳梗塞</td></tr>
						</table>
					</div>

					<p class="about-sick__text">
						そこで気圧の変化に注目し、気象病である頭痛を事前に予測できないかと開発されたのが<strong class="about-sick__text--catch">「頭痛ーる」</strong>なのです。
					</p>
				</section>

				<section class="about-uservoice">
					<div class="about-uservoice__title--wrapper">
						<h3 class="about-uservoice__title">頭痛ーるユーザーの声</h3>
					</div>

					<p class="about-uservoice__text">『気圧の変化に弱いため、なるべく備えておきたいと思い利用し始めましたが大変役立っています。<em class="about-uservoice__text--catch">事前に対策ができる</em>ので、<em class="about-uservoice__text--catch">気圧の変化による頭痛や気分の低下に振り回されにくく</em>なりました。』</p>
					<p class="about-uservoice__text">『突然の激痛に悩まされていましたが、<em class="about-uservoice__text--catch">天気予報より精密なピンポイントの気圧降下状況が分かる</em>のは本当に助かります。痛みが消える訳ではありませんが、<em class="about-uservoice__text--catch">気持ちの上で備えができる</em>のは大きいです。 』</p>
					<p class="about-uservoice__text">『<em class="about-uservoice__text--catch">気圧の変化で頭痛やめまいが起きてしまう体質</em>なので、このアプリで毎日チェックしています。私の<em class="about-uservoice__text--catch">体調管理に大いに役立っています。</em>』</p>
					<p class="about-uservoice__text">『自分の<em class="about-uservoice__text--catch">頭痛のタイミングを知ることができる</em>ので、<em class="about-uservoice__text--catch">外出時に薬の用意や服用のタイミングがわかり便利</em>です。これからも利用します。』</p>

					<p class="about-uservoice__note">※ユーザーから寄せられた個人の感想であり、「頭痛ーる」の効果・効能を示すものではありません。</p>
				</section>

				<section class="sugotoku__introduction--button">
					<a href="https://www.sugotoku.docomo.ne.jp/cs/cam/sugotoku/901/normal.html">
						<img src="@/assets/img/sugotoku_introduction_btn.jpg" alt="約200の人気・有料アプリが使い放題！ スゴ得コンテンツ 詳しくはこちら！">
					</a>
				</section>

				<section class="sugotoku__enrollment--button">
					<img class="dcm_regist_cpButton" src="@/assets/img/sugotoku_enrollment_btn.gif" alt="初回31日間無料！" >
				</section>
			</article>
		</div>
	</div>
</template>

<script>
// ライブラリ
import Swiper from 'swiper'

export default {
	name: 'NonMember',
	components: {
	},
	data () {
		return {
		}
	},
	computed: {
	},
	mounted () {
		// Swiperの処理
		const swiperConstructor = () => {
			return new Swiper('#js_swiper', {
				slidesPerView: 1.6,
				spaceBetween: 20,
				autoHeight: true,
				breakpoints: {
					640: {
						slidesPerView: 3.8,
						spaceBetween: 30
					}
				},
				navigation: {
					nextEl: '#js_swiper_next',
					prevEl: '#js_swiper_prev'
				}
			})
		}
		swiperConstructor()
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/variable';
@import "~swiper/css/swiper.min.css";

.non-member {
	&__container {
		width: 100%;
		height: 100%;
		overflow: auto;
		background: $background-primary;
	}
}

.sugotoku__enrollment--button {
	display: block;
	width: 83.2%;
	margin: 0 auto;

	img {
		width: 100%;
		cursor: pointer;
	}
}

.sugotoku__introduction--button {
	display: block;
	width: 85.334%;
	margin: 0 auto $spacing-10;

	a {
		display: block;
		text-decoration: none;
	}

	img {
		width: 100%;
		cursor: pointer;
	}
}

</style>
